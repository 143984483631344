import * as React from "react"
import Layout from "../components/layout"
import workbench from "../images/workbench.jpg"
import melting from "../images/melting.jpg"
import machine from "../images/machine.jpg"
import robot from "../images/robot.jpg"
import Seo from "../components/seo"
import "../styles/fontawesome-free-5.15.4-web/css/all.css"

  
export default ()  => (
  <>
<Layout>
  
<Seo />
<div class="pt-24" id="Hero">
  <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">   
    <div class="flex flex-col w-full md:w-5/5 justify-center items-start md:text-left">
      {/*<p class="uppercase tracking-loose w-full">Ihr zuverlässiger Partner</p>*/}   
      <h1 class="my-4 text-4xl font-bold leading-tight">
        <ul><li>Industriemaschinenservice, Werkzeug&shy;maschinenservice, Wartung und Refit - Qualitäts&shy;service aus einer Hand!</li></ul>
      </h1>
      <h1 class="leading-normal text-2xl mb-8">         
        <ul><li>Nordrefit ist ihr fachlich hochqualifizierter Ansprechpartner mit einem umfangreichen Leistungsspektrum und verfügt über ein breites Produktportfolio</li></ul>
      </h1>
    </div>
    <div class="w-full md:w-3/5 py-6 text-right">
    </div>
  </div>
</div>

<section class="bg-white border-b py-8">
  <div class="container max-w-5xl mx-auto m-8">
        <div>
            <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800" id="Unternehmen">Unser Unternehmen</h1>
            <div class="w-full mb-4">
              <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-5/6 sm:w-1/2 p-6">
                <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
                  {"Ihr strategischer Partner"}
                </h3>
                <p class="text-gray-600 mb-8">
                  {"In unserem Unternehmen verbinden wir handwerkliche Tradition und moderne Technik. Dabei reichen unsere Leistungen von der Entwicklung, über die Fertigung bis hin zum weltweiten Vertrieb. Parallel bieten wir einen umfangreichen Service. So können wir unsere Kunden ganzheitlich unterstützen."}
                </p>
              </div>
              <div class="w-full sm:w-1/2 p-6">
                <img class="w-full md:w-4/5 z-50" src={workbench} alt="robot manufaturing" />
              </div>
            </div>
            <div class="flex flex-wrap flex-col-reverse sm:flex-row">
              <div class="w-full sm:w-1/2 p-6 mt-6">
                <img class="w-full md:w-4/5 z-50" src={melting} alt="machine" />
              </div>
              <div class="w-full sm:w-1/2 p-6 mt-6">
                <div class="align-middle">
                  <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
                    {"Unsere Qualifikationen"}
                  </h3>
                  <p class="text-gray-600 mb-8">
                    {"Unsere Mitarbeiter sind nicht nur fachlich hochqualifiziert sondern haben auch den Anspruch, personalisierte Lösungen für Ihr Unternehmen zu finden. Wir stehen Ihnen jederzeit gerne beratend zur Seite. Unser Know-how und das umfangreiche Leistungsspektrum sowie unser Produktportfolio zeigen unsere Fähigkeit, maßgeschneiderte Lösungen für Ihre Anforderungen und Probleme zu konzipieren und umzusetzen. Neben unseren umfangreichen Standardservices können wir zusätzlich weitere technisch anspruchsvolle Schwerpunktbereiche für Ihr Unternehmen abdecken."}
                  </p>
                </div>
              </div>
            </div>     
        </div>
  </div>
  </section>
  <section class="bg-white border-b py-8">
  <div class="container max-w-5xl mx-auto m-8">
        <div>
            <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800" id="Dienstleistungen">Unsere Dienstleistungen</h1>
            <div class="w-full mb-4">
              <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-5/6 sm:w-1/2 p-6">
                <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
                  {"Industrie­maschinenservice und Werkzeug­maschinenservice"}
                </h3>
                <p class="text-gray-600 mb-8">
                  {"Unsere Kompetenzen decken viele Bereiche ab: Wartung und Reparatur von Elektro- und Getriebemotoren, Industrie-Getrieben, Werkzeugmaschinen (u.v.m.), die Antriebs- und Automatisierungstechnik, den allgemeinen Maschinenbau, bis hin zum kompletten Refit auf nahezu Neuniveau."}
                </p>
              </div>
              <div class="w-full sm:w-1/2 p-6">
                <img class="w-full md:w-4/5 z-50" src={robot} alt="robot manufaturing" />
              </div>
            </div>
            <div class="flex flex-wrap flex-col-reverse sm:flex-row">
              <div class="w-full sm:w-1/2 p-6 mt-6">
                <img class="w-full md:w-4/5 z-50" src={machine} alt="machine" />
              </div>
              <div class="w-full sm:w-1/2 p-6 mt-6">
                <div class="align-middle">
                  <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
                    {"Wartung und Refit"}
                  </h3>
                  <p class="text-gray-600 mb-8">
                    {"Unser breites Produkt- und Dienstleistungsspektrum machen uns zu einem Spezialisten in den genannten Gebieten. So konnten wir wertvolles Know-how sammeln um sowohl seltenste und ältere Motoren als auch Industrie-Getriebe instand zu setzen bzw. zu reparieren, Ersatzteile zu liefern oder eine Neubeschaffung auszuführen."}
                  </p>
                </div>
              </div>
            </div>     
        </div>
  </div>
  </section>
  <section class="bg-white border-b py-8" id="Kontakt">
    <div class="container max-w-5xl mx-auto m-8">
      <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
        Kontakt
      </h1>
      <div class="w-full mb-8">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="flex-auto flex-wrap text-center">
          <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
            Stellen Sie uns gerne eine Anfrage ... 
          </h3>
          <p class="text-gray-600 mb-8">
         
           Sie erreichen uns jederzeit per Mail an  <i class="fas fa-envelope"></i> info[at]nordrefit.com
          </p>
      </div>
    </div>
  </section>
</Layout>
</>
)
